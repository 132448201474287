import React, {useContext, useEffect, useRef, useState} from 'react';
import {TimeSlot} from "../../models/TimeSlot.model";
import {InputText} from "primereact/inputtext";
import {Checkbox} from "primereact/checkbox";
import {RadioButton} from "primereact/radiobutton";
import ConfirmationSummary from "./ConfirmationSummary";
import parsePhoneNumber from 'libphonenumber-js'
import {createOrder, logError} from "../../utils/Api.service";
import moment from "moment";
import ReactGA from "react-ga4";
import {useFormik} from 'formik';
import classNames from 'classnames';
import {CartContext, Types} from "../../contexts/reducers";
import {getFormattedPrice} from "../../utils/PriceUtils";
import {DiscountError} from "../../models/Cart.model";
import FatalErrorMessage from "../FatalErrorMessage/FatalErrorMessage";
import {authHeader, tennatId} from "../../utils/RealClient.service";
import Dialog from '../Footer/Dialog';
import {useTranslation} from "react-i18next";
import './Confirmation.css';
import { useCurrency } from '../../contexts/CurrencyProvider';
import PaymentMethods from './PaymentMethods';
import { usePostHog } from 'posthog-js/react';
import { useTenant } from '../../contexts/TenantProvider';
import ReactPixel from 'react-facebook-pixel';

type ConfirmationProps = {
    selectedTimeslot?: TimeSlot,
    openVoucherInput: () => void,
    setIsDataLoading: (value: boolean) => void,
    onUpdateStepIndex: (index: number) => void
    onSetLoadingScreenMessage: (message: string) => void,
    onSetLoadingScreenLink: (link: {link: string, text:string}) => void,
    isDiscountDisabled?: boolean,
    reloadItems?:() => void,
    useProductionSettings: boolean,
    className?: string,
    isRangePurchase: boolean,
}
const Confirmation = (props: ConfirmationProps) => {
    const {t} = useTranslation()
    const {currency} = useCurrency()
    const {tenant} = useTenant()
    const posthog = usePostHog()
    const { state, dispatch } = useContext(CartContext);
    const [isPurchasePerformed, setIsPurchasePerformed] = useState<boolean>(false)
    const [showErrorPurchase, setShowErrorPurchase] = useState<boolean>(false)
    const [errorPurchaseMessage, setErrorPurchaseMessage] = useState<string>("")
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>('ONLINE')
    const [checkoutLabel, setCheckoutLabel] = useState<string>('')
    const [selectedSupportOption, setSelectedSupportOption] = useState<number>(0)
    const [showSupportDialog, setShowSupportDialog] = useState<boolean>(false)
    const [orderSubmitDisabled, setOrderSubmitDisabled] = useState<boolean>(false)

    useEffect(() => {
        document.title = t("Podsumowanie");
        ReactGA._gtag("event", "begin_checkout", {
            currency: currency,
            value: state.cart.price / 100.00,
            coupon: state.cart.discounts.length > 0 ? state.cart.discounts[0].code : null,
            items: state.cart.items.map(product => {
                return {
                    "item_id": product.sku,
                    "item_name": product.name,
                    "currency": currency,
                    "price": (product.price / 100.00)
                }
            }) ?? []
        });
        ReactPixel.track('beginCheckout', {value: state.cart.price / 100.00, currency: currency})
        
        window.scrollTo(0, 0)
    }, [])

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            invoiceRequest: false,
            invoiceCompany: '',
            invoiceVat: '',
            invoiceAddress: '',
            invoiceCity: '',
            invoiceZip: '',
            paymentMethod: '',
            agreementTerms: false,
            agreementPrivacy: false,
            agreementNewsletter: false,
            paymentType: '',
            paymentConfirmedByAdmin: false
        },
        validate: (data) => {
            // data.paymentMethod = selectedPaymentMethod
            /* tslint:disable */
            let errors = {};

            if (!data.firstName) {
                // @ts-ignore
                errors.firstName = t('Imię jest wymagane');
            }
            if (!data.lastName) {
                // @ts-ignore
                errors.lastName = t('Nazwisko jest wymagane');
            }

            if (!data.email /* && (state.booking?.type === 'voucher' || data.paymentType !== 'ONSITE') */) {
                // @ts-ignore
                errors.email = t('Email jest wymagany');
            } else if (data.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                // @ts-ignore
                errors.email = t('Niepoprawny adres email. Przykładowy format: example@email.com');
            }
            if (!data.phone) {
                // @ts-ignore
                errors.phone = t('Numer telefonu jest wymagany');
            } else if (!parsePhoneNumber(data.phone, 'PL')?.isValid() && !parsePhoneNumber(data.phone, 'DE')?.isValid() ) {
                // @ts-ignore
                errors.phone =t('Numer telefonu jest niepoprawny');
            }
            if(authHeader && !data.paymentType) {
                // @ts-ignore
                errors.paymentType = t('Typ płatności wymagany')
            }
           
            if(data.paymentType === 'ONSITE' && data.paymentConfirmedByAdmin && !data.paymentMethod) {
                // @ts-ignore
                errors.paymentMethod = t('Metoda płatności jest wymagana')
            }
            // if (!data.paymentMethod) {
            //     // @ts-ignore
            //     errors.paymentMethod = 'Metoda płatności jest wymagana'
            // }
            if (data.invoiceRequest === true) {

                if (!data.invoiceCompany) {
                    // @ts-ignore
                    errors.invoiceCompany = t('Nazwa firmy jest wymagana')
                }
                if (!data.invoiceVat) {
                    // @ts-ignore
                    errors.invoiceVat = t('Numer NIP jest wymagany')
                }
                if (!data.invoiceAddress) {
                    // @ts-ignore
                    errors.invoiceAddress = t('Adres firmy jest wymagany')
                }
                if (!data.invoiceCity) {
                    // @ts-ignore
                    errors.invoiceCity = t('Miasto firmy jest wymagany')
                }
                if (!data.invoiceZip) {
                    // @ts-ignore
                    errors.invoiceZip = t('Kod pocztowy firmy jest wymagany')
                }
            }
            if(!authHeader) {
                if (!data.agreementTerms) {
                    // @ts-ignore
                    errors.agreementTerms = t('Zgoda jest wymagana')
                }
                if (!data.agreementPrivacy) {
                    // @ts-ignore
                    errors.agreementPrivacy = t('Zgoda jest wymagana')
                }
            }


            return errors;
        },
        onSubmit: (data) => {
            setOrderSubmitDisabled(true)
            posthog.capture('merch store order submitted',
            // {
            //     value: state.cart.price / 100.00,
            //     items: state.cart.items?.map(product => {
            //         return {
            //             "item_id": product.sku,
            //             "item_name": product.name,
            //             "currency": currency,
            //             "price": (product.price / 100.00)
            //         }
            //     }) ?? [],
            //     coupon: state.cart.discounts.length > 0 ? state.cart.discounts[0].code : null,
            //   }
              )
            if (state.cart.price == 0) {
                props.onSetLoadingScreenMessage(t("Twoje zamówienie zostało utworzone. Otrzymasz za chwilę maila z potwierdzeniem."))
            } else {
                props.onSetLoadingScreenMessage(t("Twoje zamówienie zostało utworzone. Przekierowujemy Ciebie na stronę płatności..."))
            }
            props.setIsDataLoading(true)
            performPurchase(data);
        }
    });
    const scrollToErrors = (errors: any) => {
        if (Object.keys(errors).length > 0) {
            document.getElementsByName(Object.keys(errors)[0])[0]?.focus();
        }
    };
    const prevSubmitCountRef = useRef(formik.submitCount);

    useEffect(() => {
        if (prevSubmitCountRef.current !== formik.submitCount && !formik.isValid) {
           scrollToErrors(formik.errors)
        }
    }, [formik.submitCount, formik.isValid]);
    const performPurchase = (data: any) => {
        const lan = state.language === 'DE' ? 'DE' : 'PL'

        let receipient = {}
        let booking = null
        if (state.booking?.type === 'voucher') {
            const rec = state.booking.owner.split(/_(.+)/)

            const phoneNumber = parsePhoneNumber(data.phone, lan)

            receipient = {
                firstName: rec[0],
                lastName: rec.length > 1 ? rec[1] : '',
                email: '',
                city: '',
                zipCode: '',
                phone: {
                    number: parseInt(phoneNumber?.nationalNumber.toString() ?? '0'),
                    prefix: phoneNumber?.countryCallingCode,
                }
            }
        } else if (state.booking?.type === 'timeslot') {
            const phoneNumber = parsePhoneNumber(data.phone, lan)

            receipient = {
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                phone: {
                    number: parseInt(phoneNumber?.nationalNumber.toString() ?? '0'),
                    prefix: phoneNumber?.countryCallingCode,
                }
            }
            booking = {
                date: moment(state.booking.day, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                time:state.booking.time,
                calendarId: state.booking.calendarId
            }
        } else {
            const phoneNumber = parsePhoneNumber(data.phone, lan)
            receipient = {
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                phone: {
                    number: parseInt(phoneNumber?.nationalNumber.toString() ?? '0'),
                    prefix: phoneNumber?.countryCallingCode,
                }
            }
        }
        let invoice = null
        if (data.invoiceRequest) {
            invoice = {
                company: data.invoiceCompany,
                taxNb: data.invoiceVat,
                address: data.invoiceAddress,
                city: data.invoiceCity,
                zipCode: data.invoiceZip
            }
        }
        const phoneNumber = parsePhoneNumber(data.phone, lan)

        const order = {
            reservationType: (state.booking?.type === 'timeslot') ? 'TIMESLOT' : (state.booking?.type === 'voucher') ? 'VOUCHER' : 'NONE',
            cart: state.cart,
            recipients: [
                receipient
            ],
            billingAddress: {
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                phone: {
                    prefix: "+" + (phoneNumber?.countryCallingCode ?? (lan === 'DE' ? "49" : "48")),
                    number: parseInt(phoneNumber?.nationalNumber.toString() ?? '0')
                }
            },
            invoiceAddress: invoice,
            paymentMethod: selectedPaymentMethod,
            // paymentProvider: "PAYNOW",
            paymentConfirmedByAdmin: data.paymentConfirmedByAdmin,
            consents: {
                privacy: data.agreementPrivacy,
                termsAndConditions: data.agreementTerms,
                newsletter: data.agreementNewsletter
            },
            booking: booking,
            customerNote: ""
        }
        
        // @ts-ignore
        createOrder(order).then(res => {
            if (!isPurchasePerformed) {
                setIsPurchasePerformed(true)
                ReactGA._gtag("event", "purchase", {
                    currency: currency,
                    value: state.cart.price / 100.00,
                    transaction_id: res.tenantOrderId,
                    coupon: state.cart.discounts.length > 0 ? state.cart.discounts[0].code : null,
                    items: state.cart.items?.map(product => {
                        return {
                            "item_id": product.sku,
                            "item_name": product.name,
                            "currency": currency,
                            "price": (product.price / 100.00)
                        }
                    }) ?? []
                });
            }
            ReactPixel.track('Purchase', {value: (state.cart.price / 100.00), currency: currency, transaction_id: res.tenantOrderId})
            posthog.capture('merch store order confirmed',
            {
                value: state.cart.price / 100.00,
                items: state.cart.items?.map(product => {
                    return {
                        "item_id": product.sku,
                        "item_name": product.name,
                        "currency": currency,
                        "price": (product.price / 100.00)
                    }
                }) ?? [],
                coupon: state.cart.discounts.length > 0 ? state.cart.discounts[0].code : null,
              }
              )
            
            try {
                localStorage.removeItem('cart')
            } catch(e) {
                logError(JSON.stringify(e), JSON.stringify({url: 'order/checkout', data: order}), '')
            }
            if (res.paymentUrl) {
                props.onSetLoadingScreenLink({link: res.paymentUrl, text: t("Jeżeli nie chcesz czekać kliknij")})
                window.scrollTo(0, 0)
                 setTimeout(() => {
                    // @ts-ignore
                    window.top.location.href = res.paymentUrl
                 }, 2500)
            } else {
                document.location.href = document.location.origin + '/order/completed?tenantId='+tennatId+'&orderId=' + res.orderId
            }
        }).catch((error) => {
            logError(JSON.stringify(error), JSON.stringify({url: 'order/checkout', data: order}), error.stack ?? '')
            setIsPurchasePerformed(false)
            props.onSetLoadingScreenMessage("")
            props.setIsDataLoading(false)
            setShowErrorPurchase(true)
            posthog.capture('merch store order error', {msg:JSON.stringify(error)})
            setErrorPurchaseMessage(error.errors.items.reduce(
                (acc:string, elem: DiscountError) => (acc + " " + elem.message),
                ""
                ))
        })
    }

    const tenantHasOnSitePaymentEnabled = () => {
        if (tenant.paymentMethods.find(x => x === 'CASH')) {
            return true
        } else {
            return false
        }
    }
    useEffect(() => {
        if (state.cart.price === 0 || authHeader) {
            setCheckoutLabel(t("ZAREZERWUJ"))
        } else {
            setCheckoutLabel(t("Zamów i zapłać") + " " + getFormattedPrice(state.cart.price, true, currency))
        }
    }, [state.cart.price])

    // @ts-ignore
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name: string) => {
        // @ts-ignore
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <React.Fragment>
            <FatalErrorMessage
                dialogVisible={showErrorPurchase}
                setDialogVisible={(value) => {
                    setShowErrorPurchase(value);
                    try {
                        localStorage.removeItem('cart')
                    } catch (e) {}
                    dispatch({type:Types.UpdateCart, payload:{...state.cart, items:[], price:0, basePrice: 0}})
                    props.onUpdateStepIndex(0)

                }}
                errorMessage={errorPurchaseMessage}
                children={<button
                    className={classNames({
                        "shadow mt-4 font-bold bg-secondary w-full font-3xl py-2 border-2 border-secondary text-white rounded-lg hover:bg-white hover:text-secondary": true,
                    })}

                    onClick={() => {
                        props.reloadItems && props.reloadItems()
                        try { 
                            localStorage.removeItem('cart')
                        } catch (e) {}
                        dispatch({type:Types.UpdateCart, payload:{...state.cart, items:[], price:0, basePrice: 0}})
                        props.onUpdateStepIndex(0)

                    }}>
                    {t('Sprawdź inne dostępne produkty')}
                </button>}

            />
            <Dialog selectedOption={selectedSupportOption} open={showSupportDialog} onClose={() => {setShowSupportDialog(false)}}/>
            <div className={props.className ? props.className : ""}>
                <ConfirmationSummary {...props}/>
                <form onSubmit={formik.handleSubmit}>
                    <div className="mb-4 bg-white px-4 py-4 lg:mt-4">
                        <div className="text-xl font-medium mb-4 ml-0 text-gray-900">{t('Dane zamawiającego')}</div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field w-full md:w-1/2">
                                <div className="mr-2">
                                    <label htmlFor="firstName" className="font-medium text-gray-700">{t('Imię')} *</label>
                                    <InputText
                                        id="firstName"
                                        name="firstName"
                                        autoFocus
                                        type="text"
                                        value={formik.values.firstName}
                                        onChange={formik.handleChange}
                                        className={classNames({'p-invalid': isFormFieldValid('firstName')})}
                                    />
                                    {getFormErrorMessage('firstName')}
                                </div>
                            </div>
                            <div className="p-field w-full md:w-1/2 ">
                                <div className="mr-2">
                                    <label htmlFor="lastName" className="font-medium text-gray-700">{t('Nazwisko')} *</label>
                                    <InputText
                                        id="lastName"
                                        name="lastName"
                                        type="text"
                                        value={formik.values.lastName}
                                        onChange={formik.handleChange}
                                        className={classNames({'p-invalid': isFormFieldValid('lastName')})}/>
                                    {getFormErrorMessage('lastName')}

                                </div>
                            </div>
                            <div className="p-field w-full md:w-1/2">
                                <div className="mr-2">
                                    <label htmlFor="email" className="font-medium text-gray-700">{t('Email')} *</label>
                                    <InputText
                                        id="email"
                                        name="email"
                                        type="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        className={classNames({'p-invalid': isFormFieldValid('email')})}/>
                                    {getFormErrorMessage('email')}

                                </div>
                            </div>
                            <div className="p-field w-full md:w-1/2">
                                <div className="mr-2">
                                    <label htmlFor="phone" className="font-medium text-gray-700">{t('Telefon')} *</label>
                                    <InputText
                                        id="phone"
                                        name="phone"
                                        type="tel"
                                        value={formik.values.phone}
                                        onChange={formik.handleChange}
                                        className={classNames({'p-invalid': isFormFieldValid('phone')})}/>
                                    {getFormErrorMessage('phone')}
                                </div>
                            </div>
                            {state.cart.price > 0 &&
                            <div className="p-field w-full">
                                <div className="mr-2">
                                    <Checkbox
                                        inputId="invoiceRequest"
                                        name="invoiceRequest"
                                        checked={formik.values.invoiceRequest}
                                        value={formik.values.invoiceRequest}
                                        onChange={formik.handleChange}
                                    />
                                    <label htmlFor="invoiceRequest" className="p-checkbox-label ml-4 mt-1 font-medium text-gray-700">{t('Potrzebuję fakturę')}</label>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    {formik.values.invoiceRequest && <div className="mb-4 bg-white px-4 pb-4 ">
                        <div className="text-xl font-bold mb-4 text-grey-900">{t('Dane faktury')}</div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field w-full md:w-1/2 ">
                                <div className="mr-2">
                                    <label htmlFor="invoiceCompany"  className="font-medium text-gray-700">{t('Nazwa firmy')} *</label>
                                    <InputText
                                        id="invoiceCompany"
                                        name="invoiceCompany"
                                        type="text"
                                        value={formik.values.invoiceCompany}
                                        onChange={formik.handleChange}
                                        className={classNames({'p-invalid': isFormFieldValid('invoiceCompany')})}
                                    />
                                    {getFormErrorMessage('invoiceCompany')}
                                </div>
                            </div>
                            <div className="p-field w-full md:w-1/2">
                                <div className="mr-2">
                                    <label htmlFor="invoiceVat" className="font-medium text-gray-700">{t('NIP')} *</label>
                                    <InputText
                                        id="invoiceVat"
                                        name="invoiceVat"
                                        type="text"
                                        value={formik.values.invoiceVat}
                                        onChange={formik.handleChange}
                                        className={classNames({'p-invalid': isFormFieldValid('invoiceVat')})}
                                    />
                                    {getFormErrorMessage('invoiceVat')}
                                </div>
                            </div>
                            <div className="p-field w-1/2 md:w-3/4">
                                <div className="mr-2">
                                    <label htmlFor="invoiceAddress" className="font-medium text-gray-700">{t('Adres')} *</label>
                                    <InputText
                                        id="invoiceAddress"
                                        name="invoiceAddress"
                                        type="text"
                                        value={formik.values.invoiceAddress}
                                        onChange={formik.handleChange}
                                        className={classNames({'p-invalid': isFormFieldValid('invoiceAddress')})}
                                    />
                                    {getFormErrorMessage('invoiceAddress')}
                                </div>
                            </div>
                            <div className="p-field w-1/2 md:w-1/4">
                                <div className="mr-2">
                                    <label htmlFor="invoiceZip" className="font-medium text-gray-700">{t('Kod pocztowy')} *</label>
                                    <InputText
                                        id="invoiceZip"
                                        name="invoiceZip"
                                        type="text"
                                        value={formik.values.invoiceZip}
                                        onChange={formik.handleChange}
                                        className={classNames({'p-invalid': isFormFieldValid('invoiceZip')})}
                                    />
                                    {getFormErrorMessage('invoiceZip')}
                                </div>
                            </div>
                            <div className="p-field w-1/2 md:w-1/2">
                                <div className="mr-2">
                                    <label htmlFor="invoiceCity" className="font-medium text-gray-700">{t('Miasto')} *</label>
                                    <InputText
                                        id="invoiceCity"
                                        name="invoiceCity"
                                        type="text"
                                        value={formik.values.invoiceCity}
                                        onChange={formik.handleChange}
                                        className={classNames({'p-invalid': isFormFieldValid('invoiceCity')})}
                                    />
                                    {getFormErrorMessage('invoiceCity')}
                                </div>
                            </div>

                        </div>
                    </div>}
                   
                    { tenantHasOnSitePaymentEnabled() && 
                        <div className="mx-0  mb-8 bg-white px-4 py-4">
                            <div className="text-xl font-medium mb-4 ml-0 text-gray-900">{t("Wybierz metodę płatności")}</div>
                            <PaymentMethods selectedPaymentMethod={selectedPaymentMethod}
                                            onPaymentMethodSelected={setSelectedPaymentMethod}/>
                        </div> 
                   
                                        }
                        <div className="mb-8 bg-white px-4 py-4 rounded">
                        <div className="text-xl font-bold mb-6  text-grey-900">{t('Newsletter & Regulaminy')}</div>
                        <div className="w-full  mb-4 flex">
                            <Checkbox inputId="agreementNewsletter"
                                    name="agreementNewsletter"
                                    checked={formik.values.agreementNewsletter}
                                    onChange={formik.handleChange}
                                    className={classNames({'p-invalid': isFormFieldValid('agreementNewsletter')})}/>
                            <label htmlFor="agreementNewsletter" className="p-checkbox-label ml-4 text-grey-700">{t('Zapisz mnie do')} <span
                                className="text-secondary border-secondary font-bold">{t('newslettera')}</span>!</label>
                        </div>
                        <div className="w-full mt-4 mb-4 flex">
                            <Checkbox
                                inputId="agreementTerms"
                                name="agreementTerms"
                                    checked={formik.values.agreementTerms}
                                    onChange={formik.handleChange}
                                    className={classNames({'p-invalid': isFormFieldValid('agreementTerms')})}/>
                            <label htmlFor="agreementTerms" className="p-checkbox-label ml-4 text-grey-700">{t('Akceptuję')} <span
                                className="text-secondary hover:cursor-pointer hover:border-b-2 border-secondary font-bold">
                                    <a href="#" onClick={() => {setShowSupportDialog(true); setSelectedSupportOption(0);}} > {t("Regulamin rezerwacji")} </a>
                            </span>. *</label>
                        </div>
                        <div className="w-full mt-4 mb-2 flex">
                            <Checkbox inputId="agreementPrivacy"
                                    name="agreementPrivacy"
                                    checked={formik.values.agreementPrivacy}
                                    onChange={formik.handleChange}
                                    className={classNames({'p-invalid': isFormFieldValid('agreementPrivacy')})}/>
                            <label htmlFor="agreementPrivacy" className="p-checkbox-label ml-4 text-grey-700">{t('Akceptuję')} <span
                                className="text-secondary hover:cursor-pointer hover:border-b-2 border-secondary font-bold">
                                    <a href="#" onClick={() => {setShowSupportDialog(true); setSelectedSupportOption(1);}} > {t('Politykę prywatności')}</a></span> 
                                     {' '} <span className="text-grey-700">{t('systemu rezerwacji BookitOne')}</span>. *
                                    </label>
                        </div>

                    </div>
                    
                   
                       
                    {!props.useProductionSettings &&  <div className={"text-red p-8"}>
                        {t('Niniejszy sklep nie został jeszcze uruchomiony. Złożenie i opłacenie zamówienia możliwe będzie w ciągu kilku dni.')}
                    </div>}
                    <button disabled={orderSubmitDisabled} className="text-lg bg-secondary text-white shadow-xl rounded
                        w-72 hover:bg-secondary-lighter flex justify-center text font-bold mx-auto mb-4 max-w-full py-2"
                            type={"submit"}
                    >{checkoutLabel}</button>
                </form>
            </div>
        </React.Fragment>
    );
};

export default Confirmation;
