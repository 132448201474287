import React, {useEffect, useState} from 'react';
import {BiCart} from "react-icons/bi";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { GiCrossedAxes, GiMachineGunMagazine } from "react-icons/gi";

import VoucherSelector from "./VoucherSelector";
import './OrderTypeSelector.scss'
import { SellableProduct } from '../../models/Product.model';
import {useTranslation} from "react-i18next";
import { FaMoneyCheckAlt } from 'react-icons/fa';
import Footer from '../Footer/Footer';


type OrderTypeSelectorProps = {
    onOrderTypeSelection: (orderType: string, index: number, tags: Array<{key:string,label:string}>) => void,
    voucherValidity: number,
    products: SellableProduct[],
    isLoading: boolean,
}

type DynamicIconsProps = {
    iconName: string,
    size: number,
    className: string
}

type OrderSelectorTypeProps = {
    type: string,
    icon: string,
    tags: Array<{key: string, label: string}>,
    title: string
    description: string
}
const OrderTypeSelector = (props: OrderTypeSelectorProps)  => {

    const {t} = useTranslation()
    const [voucherCodeVisible, setVoucherCodeVisible] = useState<boolean>(false);
    const selectorElements =[
        {type: 'packages', icon: '', tags: [{label: "Strzelanie", key: 'packages'}], title: t('Pakiety strzeleckie'), description: t('Kup i zarezerwuj wizytę lub podaruj jako voucher dla bliskiej osoby. Vouchery są ważne przez %d dni.')},
        {type: 'custom', icon: 'FaMoneyCheckAlt', title: t('Vouchery kwotowe'), description: t('Wybierz kwotę do wykorzystania w obiekcie. <br/> Broń dopasujesz na strzelnicy.'), tags: [{label: "Vouchery kwotowe", key: 'vouchers'}]},
        {type: 'custom', icon: 'GiMachineGunMagazine', title: t('Szkolenia i zawody'), description: t('Weź udział w szkoleniach i zawodach na naszej strzelnicy.'), tags: [{label: "Szkolenia", key: "trainings"},{label: "Zawody", key:"competitions"}]},
        {type: 'vouchers', icon: '', tags: [{label: "Strzelanie", key: 'packages'}], title: t('Realizacja vouchera'), description: t('Podaj kod otrzymanego vouchera i wybierz termin realizacji.')}
]
    const [elementsToShow, setElementsToShow] = useState<Array<OrderSelectorTypeProps>>([])

    useEffect(() => {
        setElementsToShow(selectorElements.filter(x => props.isLoading || x.tags.some(tag => props.products.map(prod => prod.tags).flat().map(tag => tag?.key).includes(tag.key))))
    }, [props.isLoading, props.products])

    let el: any


    return <React.Fragment>

        <div className={"top-16 left-0 w-full h-full bg-grey-100 z-40 pt-20 overflow-y-auto min-h-vh90 rounded"}>
            <div className={ "order-type-selectors-group " +(elementsToShow.length <= 2 ? "order-type-selectors-group-2-elems" : "") + (elementsToShow.length == 3 ? "order-type-selectors-group-3-elems" : "") + (elementsToShow.length == 4 ? "order-type-selectors-group-4-elems" : "")}>
                {elementsToShow.map((element, index) => (
                    <div className={"order-type-selector-wrapper"} key={index}>
                    {props.isLoading ? 
                        <Skeleton height="100%" width="100%" className='order-type-selector-loaded' />
                        : <>
                            {element.type === 'packages' && 
                            <>
                                <button type="button"  className={"order-type-selector selected"} onClick={() => props.onOrderTypeSelection('', 0, element.tags)}>
                                    <div className={"icons"}>
                                        <BiCart size={50} className="mobile-icon"/>
                                        <BiCart size={60} className="desktop-icon"/>
                                    </div>
                                    <div className={"title"}>{t(element.title)}</div>
                                    <div className="description" dangerouslySetInnerHTML={{__html: t(element.description).replace(/%d/, props.voucherValidity.toString())}}/>
                                </button>
                            </>}
                            {element.type === 'vouchers' && 
                            <>
                                <div
                                role="button" tabIndex={0}
                                    className={"order-type-selector relative " + (voucherCodeVisible ? " order-type-selector-longer" : "")}
                                    onClick={() => {
                                        setVoucherCodeVisible(true)
                                    }}
                                >
                                    <VoucherSelector voucherValidity={props.voucherValidity} voucherCodeInputVisible={voucherCodeVisible} title={element.title} description={element.description}/>
                                </div>
                            </>}
                            {element.type === 'custom' && 
                            <>
                               <button
                               type="button" tabIndex={0}
                                    className={"order-type-selector w-full "}
                                    onClick={() => props.onOrderTypeSelection(element.type, 0, element.tags)}>
                                    <div className={"icons"}>
                                        {element.icon === 'GiCrossedAxes' ?
                                        <>
                                            <GiCrossedAxes size={50} className="mobile-icon"/>
                                            <GiCrossedAxes size={50} className="desktop-icon"/>
                                        </> : 
                                        element.icon === 'FaMoneyCheckAlt' ? 
                                        <>
                                            <FaMoneyCheckAlt size={50} className="mobile-icon"/>
                                            <FaMoneyCheckAlt size={50} className="desktop-icon"/>
                                        </>
                                        :
                                        <>
                                            <GiMachineGunMagazine size={50} className="mobile-icon"/>
                                            <GiMachineGunMagazine size={50} className="desktop-icon"/>
                                        </>}
                                    </div>
                                    <div className={"title"}>{t(element.title)}</div>
                                    <div className="description" dangerouslySetInnerHTML={{__html: t(element.description)}}/>

                                </button>
                            </>}
                        </>
                    }   
                </div>
                ))}        
            </div>
            <div ref={element => {
                el = element;
            }}/>
        <Footer/>

        </div>
    </React.Fragment>
}

export default OrderTypeSelector;
