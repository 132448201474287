import React, {useContext, useEffect, useState} from 'react';
import Flag from "react-flagkit";
import './LanguageSelector.scss';
import {CartContext, Types} from "../../contexts/reducers";
import {SupportedLanguages, SupportedLanguagesFlags, SupportedLanguagesNames} from "../../utils/Languages";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import { SupportedCurrencies} from '../../utils/Currencies';
import {client} from '../../utils/RealClient.service'
import {LanguagesFullCodes} from '../../utils/Languages'
import moment from "moment";
import 'moment/locale/pl'
import 'moment/locale/en-gb'
import 'moment/locale/de'
import { getDetails } from '../../utils/Api.service';
import detectLanguage from '../../utils/LanguageDetector';
import useComponentVisible from '../../utils/useClickOutside';
import { usePostHog } from 'posthog-js/react';

type LanguageSelectorProps = {
    supportedLanguages: Array<SupportedLanguages>
}
const LanguageSelector = (props: LanguageSelectorProps) => {
    const { t, i18n } = useTranslation();
    const posthog = usePostHog()
    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };
    const {state, dispatch} = useContext(CartContext);
    const { ref, isComponentVisible, toggleComponentVisible } = useComponentVisible(false);

    const updateLocales = (lang: SupportedLanguages, curr: SupportedCurrencies, nostore = false ) => {
        posthog.capture('merch language_switched', {language: lang, currency: curr})
        moment.locale(lang.toLocaleUpperCase())

        dispatch({type: Types.UpdateLocals, payload: {language: lang, currency: curr}})
        client.defaults.headers['X-Language'] = LanguagesFullCodes[lang].toLocaleUpperCase()
        localStorage.setItem('lang', LanguagesFullCodes[lang].toLocaleUpperCase() )
        changeLanguage(lang.toLocaleLowerCase() == 'en' ? 'en-gb' : lang.toLocaleLowerCase());
        toggleComponentVisible()
        window.location.reload()
    }
    useEffect(() => {
    
        getDetails().then(res => {
            if (!res.supportedLanguages.find( x => x === detectLanguage())) {
                updateLocales(SupportedLanguages[res.defaultLanguage], SupportedCurrencies.ZLOTY, true)
            }
        })
    }, [])


    return (
        <React.Fragment>
            {props.supportedLanguages.length > 1 && 
            <>
            <button className={"language-wrapper top-2 md:top-2 lg:top-4  "} onClick={toggleComponentVisible} ref={ref}>
                <Flag country={SupportedLanguagesFlags[state.language]} className={"flag"} /> {state.language} 
                {/* / {state.currency} {SupportedCurrenciesCodes[state.currency]} <strong>| {SupportedLanguagesNames[state.language]}
                </strong> */}
                {isComponentVisible && 
            <div 
                    className="absolute top-12 right-0 bg-white z-100 shadow w-60 py-2 lg:right-4 language-dialog text-zinc-700"
                   
                    >
                <div className={"selector-elements-wrapper"}>
                    {props.supportedLanguages.map((value) => {
                        // @ts-ignore
                        const languageCode = SupportedLanguages[value];
                        // @ts-ignore
                        const languageName = SupportedLanguagesNames[value];
                        // @ts-ignore
                        const languageFlag = SupportedLanguagesFlags[value];
                        return <React.Fragment key={value}>
                            <div
                                onClick={() => updateLocales(languageCode, SupportedCurrencies.ZLOTY)}
                                className={classNames({"box-border selector-element":true, "selected":state.language === languageCode})}
                            >
                                <Flag className=" selector-lang shadow-lg " size={50} country={languageFlag}/>  <span>{languageName} {languageFlag}</span>
                            </div>
                        </React.Fragment>
                    })}
                </div>
            </div>}
            </button>
            </>}
        </React.Fragment>
    )
}

export default LanguageSelector
