import {useContext, useEffect, useState} from 'react';
import classNames from "classnames";
import {Checkbox} from "primereact/checkbox";
import {tennatId} from "../../utils/RealClient.service";
import {useTranslation} from "react-i18next";
import { CartContext } from '../../contexts/reducers';
import { LanguagesFullCodes } from '../../utils/Languages';
import { useCookiesConsent } from '../../contexts/CookiesProvider';

const CookieBanner = () => {
    const [personalization, setPersonalization] = useState(false)

    const {hasCookie,acceptAllCookies, acceptCookies, isBannerVisible,  necessary,
        preferences,
        statistics,
        thirdParty,
        session} = useCookiesConsent();

    const {t} = useTranslation()
    const {state} = useContext(CartContext);
    const [personalizedConsent, setPersonalizedConsent] = useState({
        necessary: true,
        preferences: true,
        statistics: true,
        thirdParty: true,
        session: true,
    })
    useEffect(() => {
        if (hasCookie) {
        setPersonalizedConsent({
            necessary: necessary,
            preferences: preferences,
            statistics: statistics,
            thirdParty: thirdParty,
            session: session,
        })}
    }, [isBannerVisible])
    

    return (
        <>
            {
                isBannerVisible && 
                <div className="p-dialog-mask p-component-overlay p-dialog-visible p-dialog-center"
                     style={{"zIndex": 1000, "backgroundColor": "rgba(0,0,0,.4)"}}>
                    <div className="block p-component relative w-full overflow-y-auto absolute max-h-90vh bg-white pt-8 pb-4"
                         style={{maxWidth: '920px'}}>
                        <div className={"border-b pb-2 px-8"}>
                        <h3 className={"mb-2"}><strong>{t('Ta strona korzysta z plików cookie')}</strong></h3>
                        <p>
                            {t('Wykorzystujemy pliki cookie do spersonalizowania treści i reklam, aby oferować funkcje społecznościowe i analizować ruch w naszej witrynie. Informacje o tym, jak korzystasz z naszej witryny udostępniamy partnerom społecznościowym, reklamowym i analitycznym.Partnerzy mogą połączyć te informacje z innymi danymi otrzymanymi od Ciebie lub uzyskanymi podczas korzystania z ich usług. Szczegółowe informacje o wykorzystaniu plików cookies opisane są w')}
                            <a href={"https://storeapi.bookit.one/api/v1/shop/tenant/privacy-policy?X-Language="+LanguagesFullCodes[state.language]+" &X-Tenant-ID="+tennatId+"#cookies-policy"} target="_blank" className="text-secondary border-secondary font-bold"> {t('polityce prywatności')}</a>
                        </p>
                        </div>
                        <div className={classNames({"mt-4 mb-4 px-8 border-b pb-4": true,"hidden": !personalization})}>
                            <span className="text-xl font-bold">{t('Personalizacja wyboru ciasteczek')}</span>
                            <div className="w-full flex">
                                <Checkbox inputId="necessary"
                                          name="necessary"
                                          checked={personalizedConsent.necessary}
                                          disabled={true}
                                />
                                <label htmlFor="necessary" className="p-checkbox-label ml-4">{t('Niezbędne ciasteczka')}</label>
                            </div>
                            <div className="w-full flex">
                                <Checkbox inputId="preferences"
                                          name="preferences"
                                          checked={personalizedConsent.preferences}
                                          onChange={() => setPersonalizedConsent(prev => {
                                              return {...prev, preferences: !prev.preferences}
                                          })}
                                />
                                <label htmlFor="necessary" className="p-checkbox-label ml-4">{t('Ciasteczka preferencji')}</label>
                            </div>
                            <div className="w-full flex">
                                <Checkbox inputId="statistics"
                                          name="statistics"
                                          checked={personalizedConsent.statistics}
                                          onChange={() => setPersonalizedConsent(prev => {
                                              return {...prev, statistics: !prev.statistics}
                                          })}
                                />
                                <label htmlFor="necessary" className="p-checkbox-label ml-4">{t('Ciasteczka analityki')}</label>
                            </div>
                            <div className="w-full flex">
                                <Checkbox inputId="thirdParty"
                                          name="thirdParty"
                                          checked={personalizedConsent.thirdParty}
                                          onChange={() => setPersonalizedConsent(prev => {
                                              return {...prev, thirdParty: !prev.thirdParty}
                                          })}
                                />
                                <label htmlFor="thirdParty" className="p-checkbox-label ml-4"> {t("Ciasteczka 3'rd party")}</label>
                            </div>
                            <div className="w-full flex">
                                <Checkbox inputId="session"
                                          name="session"
                                          checked={personalizedConsent.session}
                                          onChange={() => setPersonalizedConsent(prev => {
                                              return {...prev, session: !prev.session}
                                          })}
                                />
                                <label htmlFor="session" className="p-checkbox-label ml-4">{t('Ciasteczka sesji wspomagające bezpieczeństwo')}</label>
                            </div>
                        </div>
                        <div className={"mx-8 text-right mt-4"}>
                        <button
                            className={classNames({
                                "p-2 text-primary shadow-lg rounded border-2 border-secondary font-bold m-2 px-8 ": true,
                                "hidden": !personalization
                            })}
                            onClick={() => {
                                acceptCookies(personalizedConsent);
                            }}>
                            {t('Zapisz wybrane')}
                        </button>
                        <button
                            className={classNames({
                                "p-2 text-primary shadow-lg rounded border-2 border-secondary font-bold m-2 px-8": true,
                                "hidden": personalization
                            })}
                            onClick={() => {
                                setPersonalization(true)
                            }}>
                            {t('Spersonalizuj')}
                        </button>
                        <button
                            className={"p-2 text-white bg-secondary shadow-lg rounded border-2 hover:bg-secondary-darker  hover:border-secondary-darker border-secondary font-bold m-2 px-8"}
                            onClick={() => {
                                acceptAllCookies()
                            }}>{t('Zezwól na wszystkie')}
                        </button>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default CookieBanner;
