import React, {PropsWithChildren, useContext, useEffect, useState} from 'react';
import './PackageItem.scss';
import {WeaponDialog} from './WeaponDialog';
import classNames from "classnames";
import {BundleProduct} from "../../models/Product.model";
import {getImageUrl} from "../../utils/Api.service";
import LoadableImage from "../Image/LoadableImage";
import {MdOutlineInfo} from "react-icons/md";
import {getPackageLabel} from "../../utils/UnitUtils";
import { CartContext } from '../../contexts/reducers';
type PackageItemProps= {
    item: BundleProduct,
    size?: string,
    style?: string
}
export const PackageItem : React.FunctionComponent<PackageItemProps> = (props: PropsWithChildren<PackageItemProps>) => {
    const [image, setImage] = useState<string>('')
    const {state} = useContext(CartContext)
    const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false)
    useEffect(() => {
        setImage(getImageUrl(props.item.product.sku, 'large'))
    }, [])


    return (
        <React.Fragment>
            <div className={classNames(
                {
                    "inline-block min-w-24 w-27 sm:w-32 mb-2": true
                })}>

                <div role="button" className={"h-full text-center rounded sm:px-2 ml-2 mr-2 bg-grey-50 hover:bg-grey-100 butt align-top cursor-pointer  " + props.style} onClick={(e) => setIsDialogOpened(true)}>
                    {props.children}
                    <div className="relative product-description h-full">
                        <div className="cursor-pointer bg-white rounded-full text-dark hover:text-secondary absolute left-2 top-2 text-center flex justify-center">
                            <MdOutlineInfo size={20}/>
                        </div>
                        <LoadableImage
                            classes={"mix-blend-multiply product-image my-auto mx-auto justify-center w-20 h-20 object-contain"}
                            thumbnailData={"data:" + props.item.product.thumbnailType + ";base64, " + props.item.product.thumbnail}
                            imageSrc={image}
                            height={320}
                            previewAvailable={false}
                        />
                        <div className={"font-semibold text-grey-900 leading-none min-h-8 mb-10 text-sm"}>
                            {props.item.product.name}
                        </div>
                        <div className={"justify-center mb-2 absolute bottom-2 w-full"}>
                            <div className="text-xs inline-block mx-2 text-grey-600">{
                                // @ts-ignore
                                getPackageLabel(props.item.product, [], props.item.quantity, state.language).map((label) => (label))}</div>
                        </div>
                    </div>
                </div>
            </div>
            <WeaponDialog isDialogOpen={isDialogOpened} onHide={() => setIsDialogOpened(false)} item={props.item.product}/>
        </React.Fragment>
    )
}

export default PackageItem;
