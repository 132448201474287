import {Attribute, ConfigurableAttributes, ProductOption, SellableProduct, Variant} from "../../models/Product.model";
import {CartItem, CartItemOption} from "../../models/Cart.model";
import React, {useEffect, useState} from "react";
import {getCalendar, getImageUrl} from "../../utils/Api.service";
import ReactGA from "react-ga4";
import {Dialog} from "primereact/dialog";
import {AddToCartButton} from "../Packages/Buttons/AddToCartButton";
import {getFormattedPrice} from "../../utils/PriceUtils";
import {WeaponDialog} from "../Packages/WeaponDialog";
import classNames from "classnames";
import {TimeSlot} from "../../models/TimeSlot.model";
import DateTimeSelector from "../CalendarSelector/DateTimeSelector";
import {CalendarDay} from "../../models/CalendarDay.model";
import {InputText} from "primereact/inputtext";
import ClubSelector from "../ClubSelector/ClubSelector";
import moment from "moment";
import {useTranslation} from "react-i18next";
import { useCurrency } from "../../contexts/CurrencyProvider";
import { MdClose } from "react-icons/md";

type ProductsProps = {
    product: SellableProduct & ConfigurableAttributes,
    isDialogOpened: boolean,
    onIsDialogOpenedChange: (isDialogOpened: boolean) => void,
    selectedAdditions: CartItem[],
    onSelectedAdditionsChange: (selectedAdditions: CartItem[]) => void,
    onAddToCartButtonClick: (items: SellableProduct[], selectedTimeSlot?: TimeSlot, options?: CartItemOption[]) => void
}
const CustomProductDialog = (props: ProductsProps, {}) => {
    const {currency} = useCurrency()
    const [selectedVariants, setSelectedVariants] = useState<Variant[]>([])
    const [calendarDays, setCalendarDays] = useState<CalendarDay[]>()
    const [previewedPackage, setPreviewedPackage] = useState<SellableProduct | undefined>(undefined)
    const [isSelectDateTimeOpened, setIsSelectDateTimeOpened] = useState<boolean>(false)
    const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false)
    const [areMandatoryFieldsFilled, setAreMandatoryFieldsFilled] = useState<boolean>(false)
    const [image, setImage] = useState<string>('')
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlot>()
    const [cartItemOptions, setCartItemOptions] = useState<CartItemOption[]>([])
    const [price, setPrice] = useState<number>(99)
    const [options, setOptions] = useState<any>({})
    const [viewDate, setViewDate] = useState(new Date())
    const {t} = useTranslation()
    const reInitOptions = () => {
        const data: any = {}
        props.product.options.forEach((option: ProductOption) => {
            data[option.name] = ''
        })
        setOptions(data)
    }
    const selectVariant = (variant: Variant) => {
        if (false) {
            setSelectedVariants([variant])
        } else {
            setSelectedVariants((variants) => variants ? [...variants, variant] : [variant])
        }
    }
    useEffect(() => {
        if (selectedVariants || !hasVariants()) {
            const defaultOptions = props.product.options.map((option: ProductOption) => ({name: option.name, options: []}))
            setCartItemOptions((t) => (defaultOptions))
            if (props.product.calendars.length == 0) {
                return;
            }
            const duration = hasVariants() ? (selectedVariants?.reduce((acc, curr) => acc + (curr.product.duration ?? 0), 0) ?? 0 ): props.product.duration ?? 0
            const tommorow = new Date();
            tommorow.setDate(tommorow.getDate() + 1)
            tommorow.setHours(0, 0, 0, 0)
            const next = new Date()

            next.setHours(0, 0, 0, 0)
            next.setTime(tommorow.getTime() + 91 * 24 * 60 * 60 * 1000)
            //setIsDataLoading(true)§
            getCalendar(duration, tommorow.toISOString(), next.toISOString(), '', props.product.sku).then(days => {
                setCalendarDays(days)
                let selectedDate = ''

                const availableTime = days.find(day => selectedTimeSlot?.type === 'timeslot' && day.date === selectedDate)
                    ?.times.flatMap(x => x)
                    ?.find(time => selectedTimeSlot?.type === 'timeslot' && time.time === selectedTimeSlot?.time)

                if (availableTime && selectedTimeSlot?.type === 'timeslot' || selectedTimeSlot?.type === 'voucher') {
                    // dispatch({type: Types.UpdateTimeslot, payload: {...state.booking, duration: state.cart.duration}})
                } else {
                    //  dispatch({type: Types.RemoveTimeslot, payload: null})
                }
                // setIsDataLoading(false)
            }).catch(e => {// setIsDataLoading(false)
            })
        }
    }, [selectedVariants])

    useEffect(() => {
        const newImage = getImageUrl(props.product.sku, 'large')
        if (newImage !== image) {
            setImage(newImage)
        }
    }, []);
    const hasVariants = () => {
        return props.product.variants.length > 0
    }
    const requiresTimeSlot = () => {
        return props.product.calendars.length > 0
    }
    useEffect(() => {
        setPrice(selectedVariants?.reduce((acc, curr) => acc + (curr.product.price ?? 0), 0) ?? 0 ?? props.product.price)
    }, [selectedVariants])
    useEffect(() => {
        reInitOptions()
        setCartItemOptions([])
        setAreMandatoryFieldsFilled(false)
        if (props.product.options.filter((x: ProductOption ) => x.required).length == 0) {
            setAreMandatoryFieldsFilled(true)
        }
        if (!props.isDialogOpened) {
            return;
        }
        setPrice(props.product.price)
        if (hasVariants()) {
            setSelectedVariants([props.product.variants[0]])
        }
        ReactGA._gtag("event", "view_item", {
            currency: currency,
            value: (props.product.price / 100.00),
            "items": [{
                "item_id": props.product.sku,
                "item_name": props.product.name,
                "currency": currency,
                "price": (props.product.price / 100.00)
            }]
        })
    }, [props.isDialogOpened])


    const isAddToCartDisabled = () => {
        return !areMandatoryFieldsFilled || (hasVariants() && !selectedVariants ) || (requiresTimeSlot() && !selectedTimeSlot)
    }

    return <React.Fragment>
        <Dialog visible={props.isDialogOpened}
                    closable={false}
                    draggable={false}
                className="package-info-dialog relative max-h-full md:h-vh90 h-full md:w-9/10 bg-white "
                onHide={() => {reInitOptions(); setAreMandatoryFieldsFilled(false);props.onIsDialogOpenedChange(false);}} dismissableMask={true}>
                       <button
                    
                    className={"absolute top-1 right-2 md:top-2 md:right-4 p-2 hover:bg-grey rounded cursor-pointer z-20 text-primary"}
                    onClick={() => props.onIsDialogOpenedChange(false)}
                >
                    <MdClose size={25}/>
                </button>
            <div className={"fixed flex sm:hidden bottom-0 bg-white w-full p-4 pb-1 z-50 justify-center"}>
                <AddToCartButton
                    outOfStock={props.product.outOfStock}
                    className={"add-to-cart-small ml-auto"}
                                 onAddToCartClick={() => {
                                     props.onIsDialogOpenedChange(false);
                                     const itemOptions: CartItemOption[] = []
                                     if (props.product.options.length > 0) {
                                         for (const attribute  in options) {
                                             // @ts-ignore
                                             itemOptions.push({name: attribute, values: [options[attribute]]})
                                         }
                                     }
                                     if (hasVariants() && selectedVariants) {
                                         props.onAddToCartButtonClick(selectedVariants.map((variant) => variant.product), selectedTimeSlot, itemOptions)
                                     } else {
                                         props.onAddToCartButtonClick([props.product], selectedTimeSlot, itemOptions)
                                     }
                                 }}
                                 disabled={isAddToCartDisabled()}/>
            </div>
            <div className={"box-border w-full lg:w-1/2 inline-block"}>
                <div className={"hidden lg:block h-vh90 left-0 top-0 absolute w-1/2"}>
                    <img className="h-full w-full md-4 object-contain object-left-top" src={image}/>
                </div>
                <img className="lg:hidden w-full md-4 object-contain" style={{"maxHeight": "300px"}} src={image}/>
            </div>
            <div className={"w-full lg:w-1/2 inline-block align-top"}>
                <div className={"px-4"}>
                    <div>
                        { props.product.outOfStock &&
                        <div className="absolute top-5 bg-red text-white p-1 text-sm float-left mt-1 mr-2 rounded">{t('WYPRZEDANY')}</div>
                        }
                        <span className={"font-bold text-2xl "}>{props.product.name}</span>
                        <div className={"my-4 text-2xl"}>{getFormattedPrice(price, true, currency)}
                        </div>
                        {hasVariants() ?
                            <React.Fragment>
                                <div className={"text-bold mt-4"}>{props.product.variants[0].name}:</div>
                        <div className={"mt-2 flex mb-2 flex md:justify-start md:inline-flex flex-wrap"}>

                            {props.product.variants.map((variant: Variant) => (
                                <div
                                    onClick={() => {
                                        let selectedItems = selectedVariants
                                        if (props.product.customAttributes?.find((x: Attribute) => x.name === 'allowMultiple')?.value === 'true' ?? false) {
                                            if (selectedVariants.find((x:Variant) => x.product.sku === variant.product.sku)) {
                                                if (selectedVariants.length > 1) {
                                                    selectedItems = selectedVariants.filter(x => x.product.sku !== variant.product.sku)
                                                }
                                            } else {
                                                selectedItems = [...selectedVariants, variant]
                                            }
                                        } else {
                                            selectedItems = [variant]
                                        }
                                        setSelectedVariants(selectedItems)
                                        setPrice(selectedItems.reduce((acc, item) => acc + item.product.price, 0))
                                    }}
                                    key={variant.product.id}
                                    className={classNames({
                                        "cursor-pointer rounded  px-4 py-2 mr-4 mb-4 hover:border-secondary border-2": true,
                                    "border-secondary": selectedVariants.find( x=> x.product.sku === variant.product.sku)})}
                                >{variant.value}</div>
                            ))}
                        </div>
                                </React.Fragment>
                            :
                            <React.Fragment>
                                <div className={"block sm:hidden font-bold text-2xl mt-2 text-right pr-2 mb-4"}>{getFormattedPrice(price, true, currency)}
                                </div>
                            </React.Fragment>
                        }


                    </div>
                    {props.product.options.length > 0 &&
                    <React.Fragment>
                        <div className="p-fluid p-formgrid p-grid max-w-full">
                            {props.product.options.map((option: ProductOption, index:number) => (
                                <div className="p-field w-full max-w-100" key={index}>
                                    <div className="ml-2">
                                        <label htmlFor="email">{option.name} {option.required && "*"}</label>
                                        {(option.name !== 'Nazwa klubu') &&
                                            <InputText
                                                name={option.name}
                                                type="string"
                                                value={options[option.name]}
                                                onChange={event => {
                                                    const newOptions = {...options, [option.name]: event.target.value}
                                                    setOptions(newOptions)
                                                    const notFilledMandatory = props.product.options.filter((x:ProductOption) => x.required && (!newOptions.hasOwnProperty(x.name) || newOptions[x.name] === '')).length
                                                    setAreMandatoryFieldsFilled(notFilledMandatory === 0)
                                                }}
                                                className={classNames({'p-invalid': option.required && !options[option.name]})}/>
                                        } {(option.name === 'Nazwa klubu') &&
                                        <ClubSelector
                                            selectedClub={options[option.name]}
                                            onSelectedClubChange={(data) => {
                                                const newOptions = {...options, [option.name]: data}
                                                setOptions(newOptions)
                                                const notFilledMandatory = props.product.options.filter((x:ProductOption) => x.required && (!newOptions.hasOwnProperty(x.name) || newOptions[x.name] === '')).length
                                                setAreMandatoryFieldsFilled(notFilledMandatory === 0)
                                            }}
                                            priorityClubs={["COLT Wrocław", "AMATOR Wrocław", "ŚLĄSK Wrocław"]}
                                        />                        }
                                        <span>{t('Pole jest wymagane')}</span>

                                    </div>
                                </div>
                            ))}
                        </div>
                    </React.Fragment>
                    }
                    {props.product.calendars.length > 0 &&
                    <div className={"flex mb-4"}>
                        <div className={classNames({
                            "border border-2 min-w-32 px-4 mr-2 rounded text-center": true,
                            "border-secondary": !!selectedTimeSlot
                        })}>
                            {selectedTimeSlot?.type === 'timeslot' &&
                            <span className={"leading-12"}>{moment(selectedTimeSlot.day, 'DD-MM-yyyy').format("DD MMM yyyy") + " " + selectedTimeSlot.time}</span>}
                        </div>
                        <button
                            className={classNames({
                                "select-booking": !selectedTimeSlot,
                                "update-booking": !!selectedTimeSlot
                            })}
                            onClick={() => setIsSelectDateTimeOpened(true)}>
                            {!selectedTimeSlot ? t("Wybierz termin") : t("Zmień termin")}
                        </button>

                    </div>
                    }
                    <div className={"hidden sm:block pb-4 pt-1 flex lg:flex-none"}>

                        <AddToCartButton
                            className={"add-to-cart-small mx-auto lg:mx-0"}
                            outOfStock={props.product.outOfStock}
                            onAddToCartClick={() => {
                                props.onIsDialogOpenedChange(false);
                                const itemOptions: CartItemOption[] = []
                                if (props.product.options.length > 0) {
                                    for (const attribute  in options) {
                                        // @ts-ignore
                                        itemOptions.push({name: attribute, values: [options[attribute]]})
                                    }
                                }
                                if (hasVariants() && selectedVariants) {
                                    selectedVariants.forEach(variant => {
                                        props.onAddToCartButtonClick(selectedVariants.map((variant) => variant.product), selectedTimeSlot, itemOptions)
                                    })
                                } else {
                                    props.onAddToCartButtonClick([props.product], selectedTimeSlot, itemOptions)
                                }
                            }}
                            disabled={isAddToCartDisabled()}
                        />
                    </div>
                </div>

                <Dialog
                    onHide={()=> setIsSelectDateTimeOpened(false)}
                    visible={isSelectDateTimeOpened}
                    className="package-info-dialog relative  md:h-vh90 h-full bg-white px-0 sm:px-6 w-600px"
                    dismissableMask={true}
                    header={t("Wybierz termin realizacji")}
                >
                    <DateTimeSelector
                    viewDate={viewDate}
                     onMonthUpdated={(date,single) => {setViewDate(date)}}
                    skipHalfHours={false}
                    startOnFullHours={false}
                        forceSingle={true}
                        onDateTimeSelected={(date, time, calendarId) => {
                            setSelectedTimeSlot({
                                type: 'timeslot',
                                day: date,
                                time: time,
                                duration: hasVariants() ? (selectedVariants.reduce((acc, variant) => acc + (variant.product?.duration ?? 0), 0)) : (props.product.duration ?? 0),
                                calendarId: calendarId,
                            })
                            setIsSelectDateTimeOpened(false)
                        }}
                        selectedTimeSlot={selectedTimeSlot}
                        calendarDays={calendarDays}/>
                </Dialog>
                {/*{!selectedTimeSlot  ? <React.Fragment><button className={"select-booking"}> Wybierz termin realizacji</button></React.Fragment> :*/}

                {/*    <div className={"p-2 md:p-4"}>*/}
                {/*        <span className={"font-bold mb-2"}>Wybierz datę rezerwacji:</span>*/}

                {/*    </div>*/}
                {/*}*/}
                <div className={"p-2 md:p-4 border-t mb-24"}>
                    <div className={"font-bold text-xl pb-4"}>{t('Szczegóły')}</div>
                <span
                     dangerouslySetInnerHTML={{__html: props.product.description.replace(/(?:\r\n|\r|\n)/g, '<br>')}}/>
                </div>
            </div>
            {
                previewedPackage && <WeaponDialog isDialogOpen={isDialogOpened} onHide={() => setIsDialogOpened(false)}
                                                  item={previewedPackage}/>
            }


        </Dialog>
    </React.Fragment>
}

export default CustomProductDialog